<template>
    <v-card class="fill-height">
        <v-container>
            <v-row>
                <v-col>
                    <h2>Dashboard</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12" md="4" sm="12" xs="12" v-for="(widget, index) in widgetData" :key="index">
                    <WidgetCard :title="widget.title" :count="widget.count"></WidgetCard>
                </v-col>
            </v-row>
            <v-row>
                <v-col col="12">
                    <WidgetGraphCard title="Product Analyst"></WidgetGraphCard>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import WidgetCard from '@/components/Dashboard/widgets/WidgetCard.vue'
import WidgetGraphCard from '@/components/Dashboard/widgets/WidgetGraphCard.vue'
    export default {
        name: 'DashboardView',
        components: { WidgetCard, WidgetGraphCard },
        data () {
            return {
                widgetData: [
                    { title: 'Total Members', count: 41043, color: '' },
                    { title: 'Total Policies', count: 57214, color: '' },
                    { title: 'Total Transactions', count: 5274, color: '' },
                ]
            }
        }
    }
</script>

<style></style>