import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import dayjs from './plugins/dayjs';

import vueExcelXlsx from 'vue-excel-xlsx';

const toastOptions = {}

Vue.use(Toast, toastOptions);
Vue.use(vueExcelXlsx);

Vue.config.productionTip = false

Vue.filter('currencyFormat', num => {
  if (!num) {
    return '-'
  }

  const number = (num / 1).toFixed(2).replace(',','.');
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

new Vue({
  router,
  store,
  vuetify,
  dayjs,
  render: h => h(App)
}).$mount('#app')
