<template>
  <div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-on="on" v-bind="attrs" @click="toggleDarkmode(darkmode)" class="mr-1" icon small fab><v-icon>{{ darkmodeIcon(darkmode) }}</v-icon></v-btn>
      </template>
      <span>{{ darkmode ? 'Light mode' : 'Dark mode' }}</span>
    </v-tooltip>
    
  </div>
</template>

<script>
export default {
  name: 'DarkModeToggleComponent',
  data () {
    return {
      darkmode: false
    }
  },

  methods: {
    toggleDarkmode (value = Boolean) {
      this.darkmode = value
      localStorage.setItem('darkmode', value)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    },

    darkmodeIcon (value) {
      if (!value) {
        return 'mdi-moon-waxing-crescent'
      } else {
        return 'mdi-white-balance-sunny'
      }
    }
  }
}
</script>