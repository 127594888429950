<template>
  <v-main>
    <v-container class="justify-center">
      <v-row justify="center" align="center">
        <v-col md="4" sm="12" xs="12" lg="4">
          <v-card>
            <v-card-title>Login</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field id="username-textfield" @keydown.enter.prevent="login(username, password)" v-model="username" label="Username" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field id="password-textfield" @keydown.enter.prevent="login(username, password)" v-model="password" type="password" label="Password" outlined hide-details="auto"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn id="login-btn" @click="login(username, password)" :disabled="!username || !password" block class="primary">Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import authenticate from '@/api/user/authenticate';
export default {
    name: 'LoginPage',
    data () {
      return {
        username: null, password: null
      }
    },

    methods: {
      async login (username, password) {
        const response = await authenticate.login(username, password)

        if (response.token) {
          localStorage.setItem('token', response.token)
          this.$toast.success("Welcome back!")
          this.$router.push('/')
        } else {
          this.$toast.error(response.response.data.message)
        }
      }
    }
}
</script>

<style></style>