<template>
    <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
            <h1 class="blue--text">{{ count | formatNumber }} </h1>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: 'WidgetCardComponent',
        props: {
            title: {
                type: String, default: 'Title', required: false
            },
            count: {
                type: Number, default: 0, required: false
            },
            widgetColor: {
                type: String, default: undefined, required: false
            }
        },

        filters: {
            formatNumber (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
        }
    }
</script>

<style></style>