const axios = require('axios').default;
import process from 'process';

const node_env = process.env.VUE_APP_NODE_ENV;
const lumagix_api = process.env.VUE_APP_LUMAGIX_API_ENDPOINT;
const lumagix_api_local = process.env.VUE_APP_LUMAGIX_API_ENDPOINT_LOCALHOST;

const api = axios.create({
  baseURL: node_env !== 'production' ? lumagix_api_local : lumagix_api,
  withCredentials: false,
  headers: {Authorization: 'Bearer '+localStorage.getItem('token')}
});

const login = async (username, password) => {
  try {
    const response = await api.post('/api/auth/authenticate', { username, password })
    return response.data
  } catch (error) {
    return error
  }
};

// In your logout function
const logout = async () => {
  console.log('Logout function called');
  await localStorage.removeItem('token');
  // Redirect to login page or homepage
  window.location.href = '/login';  // Redirect to login page
};


export default {
  login,
  logout
}