const axios = require('axios').default;

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    headers: {Authorization: 'Bearer '+localStorage.getItem('token')} 
});

export const verifyToken = async (token) => {
    try {
        let response = await instance.post("verifyToken", token);
        return response.data;
    } catch (error) {
        throw error;
    }
};

