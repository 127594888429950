<template>
  <v-app>
    <Navbar v-if="!hideNav"></Navbar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from '@/components/Header/Navbar.vue'
import axios from 'axios';
import process from 'process';

const node_env = process.env.VUE_APP_NODE_ENV;
const lumagix_api = process.env.VUE_APP_LUMAGIX_API_ENDPOINT;
const lumagix_api_local = process.env.VUE_APP_LUMAGIX_API_ENDPOINT_LOCALHOST;

const api = axios.create({
  baseURL: node_env !== 'production' ? lumagix_api_local : lumagix_api,
  withCredentials: false,
  headers: {Authorization: 'Bearer '+localStorage.getItem('token')}
});

export default {
  name: 'App',
  components: { Navbar },
  computed: {
    hideNav () {
      return this.$route.fullPath.includes('login')
    }
  },
  mounted() {
    document.title = node_env === "production" ? "Lumagix" : "Lumagix - Development"
    this.isLogin();
    
    // Start periodic token validation
    this.startTokenCheck();
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    if (this.tokenCheckInterval) {
      clearInterval(this.tokenCheckInterval);
    }
  },
  // watch: {
  //   '$route'() {
  //     document.title = node_env === "production" ? "Lumagix" : "Lumagix - Development"
  //   }
  // },
  methods: {
    isLogin() {
      if (localStorage.getItem('token') == null) {
        this.$router.push('/login').catch(() => {});
      }
    },
    startTokenCheck() {
      // Check token immediately on load
      this.validateToken();

      // Set up a periodic check every 5 minutes
      this.tokenCheckInterval = setInterval(this.validateToken, 5 * 60 * 1000);
    },
    async validateToken() {
      const token = localStorage.getItem('token');
      if (!token) {
        this.logout();
        return;
      }

      try {
        // Assuming you have an endpoint to validate the token
        await api.get('/api/auth/validate-token', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      } catch (error) {
        console.error('Token validation failed', error);
        this.logout();
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/login').catch(() => {});
    }
  }
};
</script>
