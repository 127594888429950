import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

Vue.use(dayjs);

export default new dayjs({
  locales: ['en', 'th'],
  defaultLocale: 'en',
  defaultTimeZone: 'Asia/Bangkok',
  plugins: [
    'utc',
    'timezone',
    'relativeTime'
  ]
});