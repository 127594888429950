<template>
    <v-card>
        <v-container>
            <v-card-title>{{ title }}</v-card-title>
            <Bar id="dashboard-widget-graph" :options="chartOptions" :data="chartData" />  
        </v-container>
    </v-card>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

    export default {
        name: 'WidgetGraphCardComponent',
        components: { Bar },
        props: {
            title: {
                type: String, default: 'Title', required: false
            }
        },
        data () {
            return {
                chartOptions: {
                    responsive: true
                },
                chartData: {
                    labels: [ 'Go', 'ACPVN', 'ACPTH', 'ACPHC', 'HI5', 'PRITH', 'ACPINTL', 'Other' ],
                    datasets: [ { data: [10500, 12500, 38000, 5500, 18000, 21000, 23000, 19500 ], label: 'Policies', backgroundColor: 'orange' } ]
                },
            }
        }
    }
</script>

<style></style>