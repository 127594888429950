<template>
  <div>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center pa-0">
        <v-img :lazy-src="logo_path" max-height="230"  max-width="250" :src="logo_path"></v-img>
      </div>

      <v-spacer></v-spacer>

      <!-- center -->
      <v-btn class="ma-1 d-sm-none d-md-flex d-none" text to="/">Home</v-btn>
      <!-- Application -->
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" class="ma-1 d-sm-none d-md-flex d-none" text>Application 
                <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
        </template>
        <v-list>
            <v-list-item v-for="(item, application_menu_index) in application_menu" :key="application_menu_index" :to="item.to">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>
      </v-menu>
      <!-- Members -->
      <v-btn class="ma-1 d-sm-none d-md-flex d-none" text to="/members">Members</v-btn>
      <!-- Onboarding -->
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" class="ma-1 d-sm-none d-md-flex d-none" text>
              Onboarding
              <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, onboarding_menu_index) in onboarding_menu" :key="onboarding_menu_index" :to="item.to">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Accounting -->
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" class="ma-1 d-sm-none d-md-flex d-none" id="nav-accounting-btn" text>
              Accounting
              <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, accounting_menu_index) in accounting_menu" :key="accounting_menu_index" :to="item.to">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Setting -->
      <v-btn class="ma-1 d-sm-none d-md-flex d-none" id="nav-settings-btn" text to="/settings">Settings</v-btn>

      <v-spacer></v-spacer>
      <!-- right -->
      <!-- <DarkModeToggle/> -->
      <v-menu offset-y open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar size="48" v-on="on" v-bind="attrs">
            <v-icon large>mdi-account-circle</v-icon>
          </v-avatar>
        </template>
        <v-list>
          <v-list-item v-for="(item, user_sub_menu_index) in user_sub_menu" :key="user_sub_menu_index" :to="item.to">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list>
        <v-list-item to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-for="(group, group_menu_index) in group_menu" :key="group_menu_index" :prepend-icon="group.action" >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="group.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(sub_group, sub_group_index) in group.items" :key="sub_group_index" :to="sub_group.to">
            <v-list-item-icon>
              <v-icon>{{ sub_group.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="sub_group.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import DarkModeToggle from '../Settings/DarkModeToggle.vue'
const { logout } = require('../../api/user/authenticate');

export default {
  name: 'Navbar-Component',
  components: { DarkModeToggle },
  data () {
    return {
      logo_path: require('@/assets/lumagix-logo.png'),
      accounting_menu: [
        { title: 'Transactions', to: '/accounting/transactions', icon: '' }
      ],
      user_sub_menu: [
        { title: 'Edit Profile', to: '/user/edit', icon: '' },
        { title: 'Logout', to: '/user/logout', icon: '' },
      ],
      application_menu: [
        { title: 'Deals List', to: '/application/deals-list', icon: '' },
        { title: 'Logs', to: '/application/logs', icon: '' }
      ],
      onboarding_menu: [
        { title: 'Renewal', to: '/onboarding/renewal', icon: '' }
      ],
      drawer: false,
      group_menu: [
        {
          action: 'mdi-playlist-plus',
          items: [
            { title: 'Deal List', to: '/application/deals-list', icon: 'mdi-view-list' },
            { title: 'Logs', to: '/application/logs', icon: 'mdi-format-list-numbered' }
          ],
          title: 'Application',
        },
        {
          action: 'mdi-account-multiple',
          title: 'Member',
          items: [
            { title: 'Members', to: '/members', icon: 'mdi-account' }
          ]
        },
        {
          action: 'mdi-clipboard-account-outline',
          title: 'Onboarding',
          items: [
            { title: 'Renewal', to: '/onboarding/renewal', icon: 'mdi-autorenew' }
          ]
        },
        {
          action: 'mdi-checkbook',
          title: 'Accounting',
          items: [
            { title: 'Transactions', to: '/accounting/transactions', icon: 'mdi-transfer'}
          ]
        },
        {
          action: 'mdi-cog',
          title: 'Settings',
          items: [
            { title: 'Generate Renewal List', to: '/settings/generate-renewal-list', icon: 'mdi-refresh' },
            { title: 'Master Data', to: '/settings/master-data', icon: 'mdi-archive-outline' }
          ]
        }
      ],
      userImg: null
    }
  },

  computed: {
    checkInternet () {
      return navigator.onLine
    }
  },

  // created () {
  //   this.getRandomUserImage()
  // },

  methods: {
    async getRandomUserImage () {
      const response = await fetch('https://randomuser.me/api/')
      const data = await response.json()
      if (data) {
        this.userImg = data.results[0].picture.thumbnail
      }
    }
  }
}
</script>

<style>
.not-allowed {cursor: not-allowed;}
</style>