import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardView from '@/views/DashboardView.vue'
import LoginPage from '@/views/Login.vue'
import { verifyToken } from '@/api/apiService'
import authenticate from '@/api/user/authenticate';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: DashboardView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/user/logout',
    name: 'user-logout',
    // In your beforeEnter guard
    beforeEnter: (to, from, next) => {
      console.log('Before entering logout route');
      authenticate.logout().then(() => {
        console.log('Logout successful');
        next('/login');
      }).catch(error => {
        console.error('Logout failed:', error);
        next(); // Proceed to the next route
      });
    }
  },
  // Application Tab
  {
    path: '/application/deals-list',
    name: 'application-deals-list',
    component: () => import(/* webpackChunkName: "application-deals-list" */ '@/views/application/deals-list')
  },
  {
    path: '/application/logs',
    name: 'application-logs',
    component: () => import(/* webpackChunkName: "application-logs" */ '@/views/application/logs')
  },
  // Accounting Tab
  {
    path: '/accounting/transactions',
    name: 'accounting-transactions',
    component: () => import(/* webpackChunkName: "accounting-transactions" */ '@/views/accounting/transactions')
  },
  // Members Tab
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "members"*/ '@/views/members')
  },
  // Onboarding tab
  {
    path: '/onboarding/renewal',
    name: 'onboarding-renewal',
    component: () => import(/* webpackChunkName: "onboarding-renewal" */ '@/views/onboarding/renewal')
  },
  // Settings Tab
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/settings')
  },
  // Settings => Master Data Routes
  {
    path: '/settings/master-data',
    name: 'settings-master-data',
    component: () => import(/* webpackChunkName: "settings-master-data" */ '@/views/settings/master-data')
  },
  {
    path: '/settings/master-data/age-range',
    name: 'settings-master-data-age-range',
    component: () => import(/* webpackChunkName: "settings-master-data-age-range" */ '@/views/settings/master-data/age-range')
  },
  {
    path: '/settings/master-data/benefit-code',
    name: 'settings-master-data-benefit-code',
    component: () => import(/* webpackChunkName: "settings-master-data-benefit-code" */ '@/views/settings/master-data/benefit-code')
  },
  {
    path: '/settings/master-data/country',
    name: 'settings-master-data-country',
    component: () => import(/* webpackChunkName: "settings-master-data-country" */ '@/views/settings/master-data/country')
  },
  {
    path: '/settings/master-data/currency',
    name: 'settings-master-data-currency',
    component: () => import(/* webpackChunkName: "settings-master-data-currency" */ '@/views/settings/master-data/currency')
  },
  {
    path: '/settings/master-data/deductible',
    name: 'settings-master-data-deductible',
    component: () => import(/* webpackChunkName: "settings-master-data-deductible" */ '@/views/settings/master-data/deductible')
  },
  {
    path: '/settings/master-data/frequency',
    name: 'settings-master-data-frequency',
    component: () => import(/* webpackChunkName: "settings-master-data-frequency" */ '@/views/settings/master-data/frequency')
  },
  {
    path: '/settings/master-data/general-terms',
    name: 'settings-master-data-general-terms',
    component: () => import(/* webpackChunkName: "settings-master-data-general-terms" */ '@/views/settings/master-data/general-terms')
  },
  {
    path: '/settings/master-data/hospital',
    name: 'settings-master-data-hospital',
    component: () => import(/* webpackChunkName: "settings-master-data-hospital" */ '@/views/settings/master-data/hospital')
  },
  {
    path: '/settings/master-data/icd10',
    name: 'settings-master-data-icd10',
    component: () => import(/* webpackChunkName: "settings-master-data-icd10" */ '@/views/settings/master-data/icd10')
  },
  {
    path: '/settings/master-data/industry',
    name: 'settings-master-data-industry',
    component: () => import(/* webpackChunkName: "settings-master-data-industry" */ '@/views/settings/master-data/industry')
  },
  {
    path: '/settings/master-data/nationality',
    name: 'settings-master-data-nationality',
    component: () => import(/* webpackChunkName: "settings-master-data-nationality" */ '@/views/settings/master-data/nationality')
  },
  {
    path: '/settings/master-data/occupation',
    name: 'settings-master-data-occupation',
    component: () => import(/* webpackChunkName: "settings-master-data-occupation" */ '@/views/settings/master-data/occupation')
  },
  {
    path: '/settings/master-data/payment-method',
    name: 'settings-master-data-payment-method',
    component: () => import(/* webpackChunkName: "settings-master-data-payment-method" */ '@/views/settings/master-data/payment-method')
  },
  {
    path: '/settings/master-data/plan-group',
    name: 'settings-master-data-plan-group',
    component: () => import(/* webpackChunkName: "settings-master-data-plan-group" */ '@/views/settings/master-data/plan-group')
  },
  {
    path: '/settings/master-data/plan-type',
    name: 'settings-master-data-plan-type',
    component: () => import(/* webpackChunkName: "settings-master-data-plan-type" */ '@/views/settings/master-data/plan-type')
  },
  {
    path: '/settings/master-data/premium',
    name: 'settings-master-data-premium',
    component: () => import(/* webpackChunkName: "settings-master-data-premium" */ '@/views/settings/master-data/premium')
  },
  {
    path: '/settings/master-data/product',
    name: 'settings-master-data-product',
    component: () => import(/* webpackChunkName: "settings-master-data-product" */ '@/views/settings/master-data/product')
  },
  
  // Settings => Parameters
  {
    path: '/settings/parameters',
    name: 'settings-parameters',
    component: () => import(/* webpackChunkName: "settings-parameters" */ '@/views/settings/parameters')
  },
  // Settings => Users
  {
    path: '/settings/users',
    name: 'settings-users',
    component: () => import(/* webpackChunkName: "settings-users" */ '@/views/settings/users')
  },
  // Settings => Generate Renewal List
  {
    path: '/settings/generate-renewal-list',
    name: 'settings-generate-renewal-list',
    component: () => import(/* webpackChunkName: "settings-generate-renewal-list" */ '@/views/settings/generate-renewal-list')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
