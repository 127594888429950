import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: null
  },
  getters: {
    isAuthenticate (state) {
      return state.token !== null
    }
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
    },
    clearToken (state) {
      state.token = null
    }
  },
  actions: {
  },
  modules: {
  }
})
